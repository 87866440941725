import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Intro`}</h2>
    <p>{`Primer design tokens are named using a consistent convention to make it easier to understand what they do and how they should be used at a glance.`}</p>
    <img width="960" alt="a design token broken down by property, variant, and scale" src="https://github.com/primer/react/assets/18661030/85ec0655-0426-4a79-9ac0-0bee97e5e9b1" />
    <h2>{`Categories`}</h2>
    <img width="960" alt="graphic listing out prefix, namespace, pattern, variant, property, variant, and scale all color coded to match help illustrate the differences between them" src="https://github.com/primer/react/assets/18661030/d89da86b-5b9d-43f2-b745-126fe35ea500" />
    <p>{`This naming convention is broken down into three categories: `}<strong parentName="p">{`base, component/pattern, functional`}</strong>{`. Each category is a subset of the over-arching convention.`}</p>
    <p>{`Across all categories, the `}<a parentName="p" {...{
        "href": "#property-required"
      }}>{`property`}</a>{` value is always required. This value specifies exactly how a token is indended to be applied to a UI.`}</p>
    <h3>{`Base`}</h3>
    <p>{`Base tokens are the lowest level tokens and map directly to a raw value.`}</p>
    <img width="960" alt="a graphic illustrating five words, uniquely colored and displayed left to right in a horizontal direction. Words are prefix, namespace, pattern, variant, property, variant and scale. The pattern and variant words are dimmed to signify their exclusions from this pattern. Followed by color coded tokens, which will be described in the next section." src="https://github.com/primer/react/assets/18661030/3bffcdaf-60f1-4824-b30c-25951f2d5156" />
    <h4>{`Example `}<inlineCode parentName="h4">{`base`}</inlineCode>{` tokens`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`base-size-4
base-color-green-5
brand-base-color-lime-5
base-fontWeight-semibold
`}</code></pre>
    <h3>{`Functional`}</h3>
    <p>{`Functional tokens represent global UI patterns.`}</p>
    <img width="960" alt="a graphic illustrating five words, uniquely colored and displayed left to right in a horizontal direction. Words are prefix, namespace, pattern, variant, property, variant and scale. The namespace, pattern and variant words are dimmed to signify their exclusions from this pattern. Followed by color coded tokens, which will be described in the next section." src="https://github.com/primer/react/assets/18661030/7124ced5-96d1-4479-996a-641579a2cd4c" />
    <h4>{`Example `}<inlineCode parentName="h4">{`functional`}</inlineCode>{` tokens`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`bgColor-inset
borderColor-default
brand-borderWidth-thin
boxShadow-inset-thick
`}</code></pre>
    <h3>{`Component/pattern`}</h3>
    <p>{`Component/pattern tokens should only be used in component CSS.`}</p>
    <img width="960" alt="a graphic illustrating five words, uniquely colored and displayed left to right in a horizontal direction. Words are prefix, namespace, pattern, variant, property, variant and scale. The namespace word is dimmed to signify its exclusion from this pattern. Followed by color coded tokens, which will be described in the next section." src="https://github.com/primer/react/assets/18661030/d1d77c2f-e8c4-4d38-b295-1231bc82648f" />
    <h4>{`Example `}<inlineCode parentName="h4">{`component`}</inlineCode>{`/`}<inlineCode parentName="h4">{`pattern`}</inlineCode>{` tokens`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`control-danger-borderColor-rest
button-primary-bgColor-hover
brand-overlay-bgColor
text-codeInline-fontSize
`}</code></pre>
    <h2>{`Convention breakdown`}</h2>
    <h3>{`Prefix`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/7519380f-7cd2-4304-87b9-5556f4801af8" />
    <p>{`Prefix provides top-level encapsulation of a particular flavor of Primer, such as Primer Brand. It can be used for protected base tokens like Brand color scales, or value overrides for traditional Primer tokens in order to avoid collisions.`}</p>
    <p><inlineCode parentName="p">{`brand`}</inlineCode>{`: used for marketing/brand specific tokens.`}</p>
    <h3>{`Namespace`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/50899a02-1fac-474c-b99c-426d6853c92c" />
    <p>{`Namespace creates a scope used to identify how a token may be used. For example, `}<inlineCode parentName="p">{`base`}</inlineCode>{` tokens are the lowest level and are generally used as a reference for functional tokens (the next step above).`}</p>
    <p><inlineCode parentName="p">{`base`}</inlineCode>{`: represents global, constant values. These are the lowest level tokens and map directly to a raw value.`}</p>
    <h3>{`Pattern`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/abc16bcd-64e3-43a7-bca6-82a31190acf1" />
    <p>{`Pattern represents a group of design decisions, or a specific Primer component. Whenever possible, aim to use a name that is generic enough to influence related components. For example, the pattern `}<inlineCode parentName="p">{`control`}</inlineCode>{` can be used for multiple types of controls like buttons, inputs, or interactive action list items.`}</p>
    <p>{`For pattern and component names that are multi-word, use camelCase to separate each word.`}</p>
    <h3>{`Variant`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/6d105711-c815-488d-997c-dfd639cb8123" />
    <p>{`Variant can be used to either modify the `}<strong parentName="p">{`pattern`}</strong>{` or `}<strong parentName="p">{`property`}</strong>{`. Only one variant is allowed per token. It typically represents a stylistic variant of a token such as color (danger) or size (small).`}</p>
    <h3>{`Property (required)`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/87514222-3db7-4e98-bd81-5ce8d40d2529" />
    <p>{`Property is used to represent an item’s style. It usually matches a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Reference#index"
      }}>{`CSS property`}</a>{`, but it can also store other conceptual definitions such as `}<inlineCode parentName="p">{`size`}</inlineCode>{`, `}<inlineCode parentName="p">{`minTarget`}</inlineCode>{`, etc. Use camelCase for multi-word properties.`}</p>
    <h3>{`Scale`}</h3>
    <img width="960" alt="" src="https://github.com/primer/react/assets/18661030/9a1e8baf-66e2-494c-9ff3-0655d285f145" />
    <p>{`Scale represents ordinals to describe things like state, density, thickness, range, and speed. Scale names strive to follow our `}<a parentName="p" {...{
        "href": "https://github.com/github/primer/blob/main/adrs/2022-02-09-size-naming-guidelines.md"
      }}>{`size naming convention standards`}</a>{`.`}</p>
    <h3>{`Token name block delimiters`}</h3>
    <p>{`Individual name blocks for each token should be separated with a single character that is relevant to each Primer framework. For example, use the `}<inlineCode parentName="p">{`-`}</inlineCode>{` dash character for CSS variables and `}<inlineCode parentName="p">{`.`}</inlineCode>{` dot character for JavaScript.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="464" alt="" src="https://github.com/primer/react/assets/18661030/3694b514-2bcf-4c62-8dc2-c4bc8a3a83f4" />
    <Caption mdxType="Caption">Use dashes to separate words.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="464" alt="" src="https://github.com/primer/react/assets/18661030/15959269-4ac8-4075-88f4-788b91e6ce20" />
    <Caption mdxType="Caption">Don't use other characters to separate words.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Color modifiers`}</h2>
    <p>{`We use the following values to modify the color `}<a parentName="p" {...{
        "href": "#variant"
      }}>{`variant`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`default`}</inlineCode>{` - The default color variant for a given token. Example: `}<inlineCode parentName="li">{`fgColor-default`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`muted`}</inlineCode>{` - The secondary color variant for a given token. Example: `}<inlineCode parentName="li">{`fgColor-muted`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`emphasis`}</inlineCode>{` - The opposite of "muted", emphasis is a stronger color variant. Example: `}<inlineCode parentName="li">{`bgColor-accent-emphasis`}</inlineCode></li>
    </ul>
    <h2>{`Size modifiers`}</h2>
    <p>{`Size modifiers are often found as part of the `}<a parentName="p" {...{
        "href": "#scale"
      }}>{`scale`}</a>{` block. The following describes how we name size modifiers for each category.`}</p>
    <h3>{`General-purpose t-shirt sizes`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`xsmall | small | medium | large | xlarge | xxlarge
`}</code></pre>
    <h3>{`Density`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`normal`}</inlineCode>{` as the default size`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`condensed | normal | spacious
`}</code></pre>
    <h3>{`Thickness names`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`thin`}</inlineCode>{` as the default size`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`thin | thick | thicker
`}</code></pre>
    <h3>{`Viewport ranges names`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`narrow | regular | wide
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      